export const team = [
    {
        idMember: 1,
        name: 'Daniel',
        lastname: 'Morales',
        jobTitle: 'Desarrollador especializado',
        avatar: require('../assets/images/about/man.jpg'),
        social: [
            { idSocial: 1, type: 'instagram', path: ''},
            { idSocial: 2, type: 'facebook', path: ''}
        ]
    },
    {
        idMember: 1,
        name: 'Axel',
        lastname: 'Léon',
        jobTitle: 'Consultor de soluciones digitales',
        avatar: require('../assets/images/about/man.jpg'),
        social: [
            { idSocial: 1, type: 'linkedin', path: ''},
            { idSocial: 1, type: 'twitter', path: ''}
        ]
    },
    {
        idMember: 1,
        name: 'Julián',
        lastname: 'de la O',
        jobTitle: 'Consultor de soluciones digitales        ',
        avatar: require('../assets/images/about/man.jpg'),
        social: [
            { idSocial: 1, type: 'facebook', path: ''},
            { idSocial: 1, type: 'linkedin', path: ''}
        ]
    }
];

export const works = [
    {
        idWork: 1,
        name: 'Project name',
        preview: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing.',
        technologies: [],
        images: [
            {
                idImage: 1,
                name: 'ERMOFE',
                path: require('../assets/images/works/presentation.png'),
                type: 'fullPresentation'
            }
        ]
    },
    {
        idWork: 2,
        name: 'Project name',
        preview: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing.',
        technologies: [],
        images: [
            {
                idImage: 2,
                name: 'CORSUR',
                path: require('../assets/images/works/presentation.png'),
                type: 'fullPresentation'
            }
        ]
    },
    {
        idWork: 3,
        name: 'Project name',
        preview: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing.',
        technologies: [],
        images: [
            {
                idImage: 2,
                name: 'Otro',
                path: require('../assets/images/works/iphone-x.png'),
                type: 'fullPresentation'
            }
        ]
    },
    {
        idWork: 3,
        name: 'Project name',
        preview: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing. Lorem ipsum dolor sit amet, consectetur adipisicing.',
        technologies: [],
        images: [
            {
                idImage: 2,
                name: 'Otro',
                path: require('../assets/images/works/shirt.jpg'),
                type: 'fullPresentation'
            }
        ]
    }
];

export const FACEBOOK_URL = "https://www.facebook.com/nodeaxan";

export const INSTAGRAM_URL = "https://www.instagram.com/nodeaxan";

export const CONTACT_EMAIL_ADDRESS = "info@nodeaxan.com";

export const CMS_ROUTE = "https://cockpit.nodeaxan.com";