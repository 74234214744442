import React, { useState } from 'react';
import { AppBar, createStyles, Drawer, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            padding: theme.spacing(1.5),
            color: theme.palette.secondary.main
        },
        logo: {
            maxWidth: 28
        },
        paper: {
            height: '100vh',
            padding: theme.spacing(1.5)
        },
        pages: {
            maxWidth: 900,
            margin: '0 auto',
            '& a': {
                fontSize: 45,
                textDecoration: 'none',
                textTransform: 'uppercase',
                color: theme.palette.primary.light,
                fontWeight: theme.typography.fontWeightBold,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 60
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: 65
                },
                '&:hover': {
                    color: theme.palette.primary.main
                }
            }
        },
        contact: {
            textAlign: 'right',
            '& a': {
                color: theme.palette.primary.light,
                '&:hover': {
                    color: theme.palette.primary.main
                }
            }
        },
        menu: {
            textAlign: 'right',
            '& > span': {
                padding: 0
            }
        }
    })
);

const Navbar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <AppBar className={classes.appbar} position='sticky' color='transparent' elevation={0}>
                <Grid container alignItems='center'>
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <Link to='/'>
                            <img className={classes.logo} src={require('../../assets/images/nodeaxan.svg')} />
                        </Link>
                    </Grid>
                    <Grid item xs={6} className={classes.menu}>
                        <IconButton component='span' color='primary' onClick={() => setOpen(true)}>
                            <Menu />
                        </IconButton>
                    </Grid>
                </Grid>

                <Drawer
                    anchor='top'
                    classes={{ paper: classes.paper }}
                    open={open}
                    elevation={0}
                    transitionDuration={{
                        enter: 650,
                        exit: 650,
                        appear: 650
                    }}
                >
                    <Grid container direction='column' wrap='nowrap' justify='center' spacing={1} style={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Grid container justify='space-between' alignItems='center'>
                                <Grid item xs={6} style={{ textAlign: 'left' }}>
                                    <Link to='/'>
                                        <img className={classes.logo} src={require('../../assets/images/nodeaxan.svg')} />
                                    </Link>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <IconButton component='span' color='primary' onClick={() => setOpen(false)}>
                                        <Close className={classes.icon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='column' className={classes.pages}>
                                <Grid item>
                                    <Link to='/'>Inicio</Link>
                                </Grid>
                                <Grid item>
                                    <Link to='/portafolio'>Portafolio</Link>
                                </Grid>
                                <Grid item>
                                    <Link to='/services'>Servicios</Link>
                                </Grid>
                                <Grid item>
                                    <Link to='/about'>Nosotros</Link>
                                </Grid>
                                <Grid item>
                                    <Link to='/contact'>Contacto</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} direction='column' alignItems='flex-end' className={classes.contact}>
                                <Grid item>
                                    <Typography variant='body1'>Contacto</Typography>

                                    <a href='mailto:info@nodeaxan.com' target='_blank' rel='noopener noreferrer'>info@nodeaxan.com</a>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1'>Redes sociales</Typography>

                                    <a href='#' target='_blank' rel='noopener noreferrer' style={{ marginRight: 20 }}>Facebook</a>
                                    <a href='#' target='_blank' rel='noopener noreferrer'>Instagram</a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Drawer>
            </AppBar>
        </>
    );
};

export default Navbar;