import React from 'react';
import { createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { Facebook, Instagram } from '@material-ui/icons';
import { CONTACT_EMAIL_ADDRESS, FACEBOOK_URL, INSTAGRAM_URL } from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            marginTop: theme.spacing(5),
            paddingBottom: theme.spacing(5)
        },
        name: {
            marginBottom: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular
        },
        info: {
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary.light,
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.primary.main
                }
            }
        },
        pages: {
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary.light,
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.primary.main
                }
            }
        },
        social: {
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary.light
            }
        },
        copyright: {
            textAlign: 'center',
            marginTop: theme.spacing(5),
            color: theme.palette.grey[500]
        }
    })
);

const Footer = () => {
    const classes = useStyles();

    return (
        <>
            <Divider style={{ marginTop: 45 }} />
            <footer className={classes.footer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} className={classes.info}>
                        <Typography variant='h6' color='secondary' className={classes.name}>NodeAxan</Typography>

                        <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`} rel='noopener noreferrer'>{CONTACT_EMAIL_ADDRESS}</a>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant='h6' color='secondary' className={classes.name}>Menú</Typography>

                        <Grid container direction='column' spacing={1} className={classes.pages}>
                            <Grid item>
                                <Link to='/'>Inicio</Link>
                            </Grid>
                            <Grid item>
                                <Link to='/portafolio'>Portafolio</Link>
                            </Grid>
                            <Grid item>
                                <Link to='/services'>Servicios</Link>
                            </Grid>
                            <Grid item>
                                <Link to='/about'>Nosotros</Link>
                            </Grid>
                            <Grid item>
                                <Link to='/contact'>Contacto</Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant='h6' color='secondary' className={classes.name}>Redes sociales</Typography>

                        <Grid container spacing={1} className={classes.social}>
                            <Grid item>
                                <a href={FACEBOOK_URL} target='_blank' rel='noopener referrer'>
                                    <Facebook />
                                </a>
                            </Grid>
                            <Grid item>
                                <a href={INSTAGRAM_URL} target='_blank' rel='noopener referrer'>
                                    <Instagram />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <div className={classes.copyright}>
                    <small>Copyright {new Date().getFullYear()} © - Desarrollado por nodeaxan</small>
                </div>
            </footer>
        </>
    );
};

export default Footer;