/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, createMuiTheme, createStyles, CssBaseline, makeStyles, Theme, ThemeProvider } from "@material-ui/core"

import Navbar from "./layouts/navbar"
import AsideLeft from "./layouts/aside-left"
import AsideRight from "./layouts/aside-right"
import Footer from "./layouts/footer"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2962ff',
      light: '#768fff',
      dark: '#0039cb',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#212121',
      light: '#484848',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#212121'
    }
  },
  typography: {
    h1: {
      fontWeight: 900,
    },
    h2: {
      fontWeight: 900,
      fontSize: '2.5rem'
    },
    h3: {
      fontWeight: 900,
      fontSize: '1.6rem'
    },
    h4: {
      fontWeight: 900,
      fontSize: '1.3rem'
    },
    h5: {
      fontWeight: 900,
      fontSize: '1.1rem'
    },
    h6: {
      fontWeight: 900,
      fontSize: '1.1rem'
    },
    fontFamily: [
      'Lato',
      'Open Sans',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiButton: {
      contained: {
        textTransform: 'uppercase',
        borderRadius: 100
      },
      containedPrimary: {
        boxShadow: `0px 3px 1px -2px rgba(41,98,255,0.2), 0px 2px 2px 0px rgba(41,98,255,0.14), 0px 1px 5px 0px rgba(41,98,255,0.12)`,
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(41,98,255,0.2), 0px 4px 5px 0px rgba(41,98,255,0.14), 0px 1px 10px 0px rgba(41,98,255,0.12)'
        }
      }
    }
  },
  shadows: [
    "none",
    "0px 2px 1px rgb(205, 205, 205)",
    "0px 3px 5px rgba(205, 205, 205, 0.95)",
    "0px 4px 10px rgba(205, 205, 205, 0.90)",
    "0px 5px 15px rgba(205, 205, 205, 0.85)",
    "0px 6px 20px rgba(205, 205, 205, 0.80)",
    "0px 7px 25px rgba(205, 205, 205, 0.75)",
    "0px 8px 20px rgba(205, 205, 205, 0.70)",
    "0px 9px 20px rgba(205, 205, 205, 0.65)",
    "0px 10px 25px rgba(205, 205, 205, 0.60)",
    "0px 11px 25px rgba(205, 205, 205, 0.55)",
    "0px 12px 25px rgba(205, 205, 205, 0.50)",
    "0px 13px 25px rgba(205, 205, 205, 0.45)",
    "0px 14px 25px rgba(205, 205, 205, 0.40)",
    "0px 15px 25px rgba(205, 205, 205, 0.35)",
    "0px 16px 25px rgba(205, 205, 205, 0.30)",
    "0px 17px 25px rgba(205, 205, 205, 0.25)",
    "0px 18px 25px rgba(205, 205, 205, 0.20)",
    "0px 19px 25px rgba(205, 205, 205, 0.15)",
    "0px 20px 25px rgba(205, 205, 205, 0.10)",
    "0px 21px 25px rgba(205, 205, 205, 0.5)",
    "none",
    "none",
    "none",
    "none"
  ]
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1400,
      margin: '0 auto',
      paddingLeft: theme.spacing(6.5),
      paddingRight: theme.spacing(6.5),
      '& h1': {
        fontSize: 35,
        [theme.breakpoints.up(0)]: {
          fontSize: 'calc(35px + 40 * (100vw - 0px) / 1500)'
        },
        [theme.breakpoints.up(1400)]: {
          fontSize: 70
        }
      }
    }
  })
);

const Layout = ({ children, page }) => {
  const classes = useStyles();
  // ! Ignoro para qué era esto. Se comenta para permitir el render (En gatsby no se debe usar window por temas de SSR)
  // const path = window.location.pathname;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <AsideLeft page={page} />
        {/* {path === '/' ? <AsideRight /> : null} */}
        <div className={classes.container}>
          <Container style={{ maxWidth: 1400, padding: 0 }}>{children}</Container>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired
}

export default Layout
