import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        asideLeft: {
            width: 42.7,
            whiteSpace: 'nowrap',
            paddingTop: theme.spacing(9),
            paddingRight: theme.spacing(1),
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            transform: 'rotate(180deg)',
            '& p': {
                transform: 'rotate(90deg)'
            }
        }
    })
);

const AsideLeft = ({ page }) => {
    const classes = useStyles();

    return (
        <aside className={classes.asideLeft}>
            <p>{page}</p>
        </aside>
    );
};

export default AsideLeft;